import { useState } from "react";

export function SettingGallery() {
  const data = [
    {
      imgelink:
        "/nursery-view-cropped.jpg",
    },

    {
      imgelink:
        "/garden.jpg",
    },

    {
      imgelink:
        "/setting.jpg",
    },
    {
       imgelink: "./card.jpg" 
    },
    {
       imgelink: "./card-2.jpg"
    }
  ];

  const [active, setActive] = useState(
    "/nursery-view-gallery.jpg",
  );

  return (
    <div className="container px-5 py-24 mx-auto flex flex-col">
        <div className="flex w-full mb-20 flex-wrap">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">Gallery</h1>
                    {/*
                    <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom.</p>
                    */}
        </div>
    <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>

      <div className="grid grid-cols-5 gap-4">
        {data.map(({ imgelink }, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className="object-cover object-center h-20 max-w-full rounded-lg cursor-pointer"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}


export default SettingGallery;