

function AboutUs() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto flex flex-col">
                <div className="lg:w-4/6 mx-auto">
                    <div className="rounded-lg h-64 overflow-hidden">
                        <img alt="content" className="object-cover object-center h-full w-full" src="/nursery-view-cropped.jpg"/>
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                    <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                        <div className="w-20 h-20 rounded-full inline-flex items-center justify-center">
                            {/*
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10" viewBox="0 0 24 24">
                                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            */}
                            <img alt="tugba" className="w-40 h-30" src="/profile-tugba.jpg"/>
                    </div>
                    <div className="flex flex-col items-center text-center justify-center">
                        <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Tuba Sen</h2>
                        <div className="w-12 h-1 bg-pink-500 rounded mt-2 mb-4"></div>
                        <p className="text-base">Ofsted Registered Childminder.</p>
                    </div>
            </div>
            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">Tuba and Tayfun, siblings, have worked together to found this nursery. Tuba, a former Teacher and Tayfun, a Software Developer, together have turned their loving home to a loving nursery.</p>
                <p className="leading-relaxed text-lg mb-4">It did not come true with a blink of an eye nor did it happen with a snap of a finger. It’s the outcome of a long, tiring but fun process that we have enjoyed every step of the journey. And it all started with a wish. A wish to bring joy and curiosity to the lives of every child we meet along the way.</p>
                <p className="leading-relaxed text-lg mb-4">Our setting consists of an open plan kitchen, two bathrooms, a play area, sleeping room and a backyard garden. The play area has the advantage of abundant natural light for most of the day.</p>
                <p className="leading-relaxed text-lg mb-4">We have set up the garden as an outdoor learning environment which is compliant with health and safety regulations in the EYFS. By also supporting your child’s development and curiosity. We have a separate sleeping room upstairs. The setting is located in a lovely quiet and safe cul-de-sac. </p>
                
            </div>
            </div>
        </div>
        </div>
        </section>
    )
}

export default AboutUs;