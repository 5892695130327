

function Intro() {
    return (
        <>
        <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                        The One Childcare
                    </h1>
                <p className="mb-8 leading-relaxed">We are a home nursery based in Lee Green area of London,
                        welcoming every children from the age of 8 months to/and five years old, with a ratio of one
                        adult/practitioner to three children.</p>
                <div className="flex justify-center">
                    <a href="#contact-us">
                    <button className="inline-flex text-white bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">Contact Us</button>
                    </a>
                </div>
            </div>
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <img className="object-cover object-center rounded" alt="hero" src="/header-image.jpg"/>
            </div>
            </div>
        </section>
        </>
    )
}


export default Intro;