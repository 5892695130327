

function OffstedRegistered() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-12">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Ofsted Registered</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">We are Ofsted Registered since February 2024.</p>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base font-semibold py-8">What does this mean?</p>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">We have successfully passed a visit by an Ofsted Inspector. This means we meet the
        requirements to run our individual business as a childminder and home nursery. We comply with the
        regulations of Ofsted.</p>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base py-4">
        Ofsted inspects childminders by law. Every thirty months,they carry out compliance inspections after the initial visit. They inspect childminders under their education inspection framework. These inspections give parents information about the education and care their child is receiving.
        </p>  
    </div>
    <div className="flex flex-col items-center mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
      <a href="#contact-us">  
      <button className="text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-600 rounded text-lg">Contact Us</button>
      </a>
    </div>
  </div>
</section>
    )
}

export default OffstedRegistered;