import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Intro from './components/Intro';
import ContactUs from './components/Contact';
import OffstedRegistered from './components/OffstedRegistered';
import Qualifications from './components/Qualifications';
import SettingGallery from './components/SettingGallery';
import ReactGA from 'react-ga4';


const TRACKING_ID = "G-456827597";

function App() {
  ReactGA.initialize(TRACKING_ID);
  return (
    <>
      <Header />
      <Intro />
      <AboutUs />
      <Qualifications />
      <OffstedRegistered />
      <SettingGallery />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
